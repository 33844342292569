// ScheduleColumn.js
import React, { useState, useEffect, useRef } from 'react';
import './MatchSchedule.css';
import TextField from '@mui/material/TextField';
import { useDrag, useDrop } from 'react-dnd';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ItemTypes = {
  JOB: 'JOB',
  CREW_LIST: 'CREW_LIST',
};

// DroppableJob Component
function DroppableJob({ job, index, date, crewColors, moveJob, assignCrewToJob, employees }) {
    const ref = useRef(null);

    const [{ isOver }, drop] = useDrop({
      accept: [ItemTypes.JOB, ItemTypes.CREW_LIST],
      hover: (item, monitor) => {
        // OPTIONAL: If you want live “highlighting” or any hover-based logic, do it here.
        // Just DON’T call moveJob here if you only want a final drop-based reorder.
      },
      drop: (item, monitor) => {
        if (!ref.current || !item) return;
  
        if (item.type === ItemTypes.JOB) {
          const dragDate = item.date;
          const dragIndex = item.index;
          const dropDate = date.format('YYYY-MM-DD');
          const dropIndex = index;
  
          // Only reorder if it's a different spot
          if (dragDate === dropDate && dragIndex === dropIndex) return;
  
          // Finalize the move on drop
          moveJob(dragDate, dragIndex, dropDate, dropIndex);
  
          // Update item to reflect new index/date
          item.index = dropIndex;
          item.date = dropDate;
        }
        else if (item.type === ItemTypes.CREW_LIST) {
          const crewIndex = item.index;
          assignCrewToJob(date.format('YYYY-MM-DD'), index, crewIndex);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver()
      })
    });
  
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.JOB,
      item: {
        id: job.id,
        index,
        date: date.format('YYYY-MM-DD'),
        type: ItemTypes.JOB
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
  
    drag(drop(ref));
  
    const opacity = isDragging ? 0.5 : 1;

  // Get employee names
  const employeeNames = employees
    .filter(emp => job.employee_oids && job.employee_oids.includes(emp.oid))
    .map(emp => emp.name);

  return (
    <div
      ref={ref}
      className="droppable-job"
      style={{
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: job.crewIndex !== undefined ? crewColors[job.crewIndex % crewColors.length] : '#f0f0f0',
        opacity,
        transition: 'background-color 0.2s ease, opacity 0.2s ease',
        marginBottom: '10px',
        cursor: 'move',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <span>{job.neighborhood}</span> */}
          <Chip label={`  ${job.builder} > ${job.neighborhood} > ${job.lot_num} `} style={{ backgroundColor: crewColors[job.crewIndex % crewColors.length] }} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{job.phase_type}</span>
        {job.crewIndex !== undefined && (
          <Chip label={`Crew ${job.crewIndex + 1}`} style={{ backgroundColor: crewColors[job.crewIndex % crewColors.length] }} />
        )}
      </div>
      {employeeNames.length > 0 && (
        <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          {employeeNames.map((name, idx) => (
            <Chip key={idx} label={name} size="small" />
          ))}
        </div>
      )}
    </div>
  );
}

// ScheduleColumn Component
function ScheduleColumn({ columnIndex, date, crewColors, crews, assignCrewToJob, moveJob, jobs, onDateChange, employees }) {
  const [selectedDate, setSelectedDate] = useState(date);

  // Notify MatchSchedule of the initial date
  useEffect(() => {
    onDateChange(columnIndex, selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    onDateChange(columnIndex, newDate);
  };

  return (
    <div className="match-schedule-column schedule">
      <span className='title'>{selectedDate.format('YYYY-MM-DD')}</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} style={{ marginBottom: '20px' }} />}
        />
        {jobs.map((job, index) => (
          <DroppableJob
            key={job.id} // Ensure each job has a unique identifier
            job={job}
            index={index}
            date={selectedDate}
            crewColors={crewColors}
            moveJob={moveJob}
            assignCrewToJob={assignCrewToJob}
            employees={employees}
          />
        ))}
      </LocalizationProvider>
    </div>
  );
}

export default ScheduleColumn;
