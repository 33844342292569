// src/components/schedule3/MatchSchedule.js

import React, { useEffect, useState, useCallback } from 'react';
import './MatchSchedule.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CentralCalls from '../../centralCalls';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import dayjs from 'dayjs';
import ScheduleColumn from './ScheduleColumn'; // Ensure the path is correct

const crewColors = [
    '#ffebee', '#f8bbd0', '#e1bee7', '#d1c4e9', '#bbdefb',
    '#b3e5fc', '#b2dfdb', '#dcedc8', '#fff9c4', '#ffe0b2'
];

// DraggableCrewList Component
function DraggableCrewList({ crew, onClick, onDelete, showDelete, isSelected, index }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'CREW_LIST',
        item: crew.length > 0 ? { ids: crew.map(employee => employee.oid), index, type: 'CREW_LIST' } : { index, type: 'CREW_LIST' },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [crew, index]);

    return (
        <div
            ref={crew.length > 0 ? drag : null}
            onClick={onClick}
            className="draggable-crew-list"
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: crew.length > 0 ? 'grab' : 'default',
                padding: '10px',
                border: isSelected ? '3px solid #0277bd' : '1px solid #ccc',
                borderRadius: '5px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                position: 'relative',
                backgroundColor: crewColors[index % crewColors.length],
                minHeight: '60px',
                flexGrow: 1,
            }}
        >
            {crew.map((employee) => (
                <Chip key={employee.oid} label={employee.name} />
            ))}
            {showDelete && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                    size="small"
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            )}
        </div>
    );
}

function MatchSchedule() {
    const [employees, setEmployees] = useState([]);
    const [crews, setCrews] = useState([[]]);
    const [selectedCrewIndex, setSelectedCrewIndex] = useState(0);

    // Initialize with three dates: today, tomorrow, and day after tomorrow
    const initialDates = [dayjs(), dayjs().add(1, 'day'), dayjs().add(2, 'day')];
    const [columns, setColumns] = useState(initialDates);

    // Centralized jobs state: { '2023-10-05': [...jobs], '2023-10-06': [...jobs], ... }
    const [jobsByDate, setJobsByDate] = useState({});

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const people = await CentralCalls.fetchTableData('employee', { inactive: 0 });
                setEmployees(people);
            } catch (error) {
                console.error('Failed to fetch employees:', error);
                setEmployees([]);
            }
        };
        fetchEmployees();
    }, []);

    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                const allJobs = {};
                for (const date of columns) {
                    const formattedDate = date.format('YYYY-MM-DD');
                    const jobs = await CentralCalls.callStoredProcedureWithParams('sp_getOpenScheduleByDate', { date: CentralCalls.sqlFormatdate(date) });
                    allJobs[formattedDate] = jobs;
                }
                setJobsByDate(allJobs);
            } catch (error) {
                console.error('Failed to fetch jobs:', error);
                // Initialize empty arrays for each date in case of failure
                const emptyJobs = {};
                for (const date of columns) {
                    const formattedDate = date.format('YYYY-MM-DD');
                    emptyJobs[formattedDate] = [];
                }
                setJobsByDate(emptyJobs);
            }
        };
        fetchAllJobs();
    }, [columns]);

    const handleEmployeeChange = (event, newValue) => {
        const updatedCrews = [...crews];
        updatedCrews[selectedCrewIndex] = newValue || [];
        setCrews(updatedCrews);
    };

    const addNewCrew = () => {
        setCrews(prevCrews => {
            const newCrews = [...prevCrews, []];
            setSelectedCrewIndex(newCrews.length - 1);
            return newCrews;
        });
    };

    const deleteCrew = (index) => {
        if (crews.length > 1) {
            const updatedCrews = crews.filter((_, i) => i !== index);
            setCrews(updatedCrews);
            setSelectedCrewIndex(0);
        }
    };

    /**
     * Move a job from one position to another, possibly across different dates.
     * @param {string} sourceDate - The date from which the job is moved (formatted 'YYYY-MM-DD').
     * @param {number} sourceIndex - The index of the job in the source date.
     * @param {string} targetDate - The date to which the job is moved (formatted 'YYYY-MM-DD').
     * @param {number} targetIndex - The index in the target date where the job should be inserted.
     */
    const moveJob = useCallback(
        (sourceDate, sourceIndex, targetDate, targetIndex) => {
          setJobsByDate(prevJobs => {
            // If moving within the same date:
            if (sourceDate === targetDate) {
              const updatedJobs = [...prevJobs[sourceDate]];
              // Remove the job from sourceIndex
              const [movedJob] = updatedJobs.splice(sourceIndex, 1);
      
              // If targetIndex is after sourceIndex, we adjust targetIndex
              // because we've already removed the item from the array
              let adjustedIndex = targetIndex;
              if (targetIndex > sourceIndex) {
                adjustedIndex = targetIndex - 1;
              }
      
              // Insert into the new position
              updatedJobs.splice(adjustedIndex, 0, movedJob);
      
              return {
                ...prevJobs,
                [sourceDate]: updatedJobs,
              };
            } else {
              // Moving across different dates
              const sourceJobs = [...prevJobs[sourceDate]];
              const [movedJob] = sourceJobs.splice(sourceIndex, 1);
      
              // If target date doesn't exist, default to empty array
              const targetJobs = prevJobs[targetDate]
                ? [...prevJobs[targetDate]]
                : [];
      
              // Insert into the target position
              targetJobs.splice(targetIndex, 0, movedJob);
      
              // Return a new state object with updated arrays
              return {
                ...prevJobs,
                [sourceDate]: sourceJobs,
                [targetDate]: targetJobs,
              };
            }
          });
        },
        []
      );
      

    /**
     * Update a job's crew assignment.
     * @param {string} date - The date of the job (formatted 'YYYY-MM-DD').
     * @param {number} jobIndex - The index of the job within the date.
     * @param {number} crewIndex - The index of the crew assigned to the job.
     */
    const assignCrewToJob = useCallback((date, jobIndex, crewIndex) => {
        setJobsByDate(prevJobs => {
            const updatedJobs = [...prevJobs[date]];
            updatedJobs[jobIndex].employee_oids = crews[crewIndex].map(emp => emp.oid);
            updatedJobs[jobIndex].crewIndex = crewIndex;
            return {
                ...prevJobs,
                [date]: updatedJobs,
            };
        });
    }, [crews]);

    /**
     * Handle date change from ScheduleColumn.
     * @param {number} columnIndex - The index of the column whose date has changed.
     * @param {dayjs} newDate - The new date selected.
     */
    const handleDateChange = useCallback(async (columnIndex, newDate) => {
        const formattedNewDate = newDate.format('YYYY-MM-DD');
        try {
            const jobs = await CentralCalls.callStoredProcedureWithParams('sp_getOpenScheduleByDate', { date: CentralCalls.sqlFormatdate(newDate) });
            setJobsByDate(prevJobs => ({
                ...prevJobs,
                [formattedNewDate]: jobs,
            }));
            setColumns(prevColumns => {
                const updatedColumns = [...prevColumns];
                updatedColumns[columnIndex] = newDate;
                return updatedColumns;
            });
        } catch (error) {
            console.error(`Failed to fetch jobs for ${formattedNewDate}:`, error);
            setJobsByDate(prevJobs => ({
                ...prevJobs,
                [formattedNewDate]: [],
            }));
        }
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="match-schedule-root">
                <div className="match-schedule-column crew">
                    <span className='title'>
                        Crew
                        <IconButton onClick={addNewCrew} size="small">
                            <AddIcon />
                        </IconButton>
                    </span>
                    <Autocomplete
                        multiple
                        options={employees}
                        getOptionLabel={(option) => option.name || ''}
                        value={crews[selectedCrewIndex]}
                        onChange={handleEmployeeChange}
                        isOptionEqualToValue={(option, value) => option.oid === value.oid}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={option.oid}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <FormControl fullWidth required>
                                <TextField {...params} label="Select Employees" variant="standard" required />
                            </FormControl>
                        )}
                    />
                    {crews.map((crew, index) => (
                        <span key={index} style={{ marginTop: '15px' }}>
                            <DraggableCrewList
                                crew={crew}
                                onClick={() => setSelectedCrewIndex(index)}
                                onDelete={() => deleteCrew(index)}
                                showDelete={crews.length > 1}
                                isSelected={index === selectedCrewIndex}
                                index={index}
                            />
                        </span>
                    ))}
                </div>

                <div className="match-schedule-columns">
                    {columns.map((date, index) => (
                        <ScheduleColumn
                            key={date.format('YYYY-MM-DD')}
                            columnIndex={index}
                            date={date}
                            crewColors={crewColors}
                            crews={crews}
                            assignCrewToJob={assignCrewToJob}
                            moveJob={moveJob}
                            jobs={jobsByDate[date.format('YYYY-MM-DD')] || []}
                            onDateChange={handleDateChange}
                            employees={employees}
                        />
                    ))}
                </div>
            </div>
        </DndProvider>
    );
}
export default MatchSchedule;